import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { withFlexCSS, WithFlexProps } from 'app/shared/components/styleUtils';
import { ActionButton } from 'app/shared/components/molecules';

interface SharedProps {
  onClick: () => void;
  showButton: boolean;
}
interface Props extends SharedProps {
  intlId: string;
  filename: string;
  'data-qaid': string;
}

const StyledButton = styled(ActionButton)<WithFlexProps>`
  ${({ theme }) => css`
    background: ${theme.colors.primary.black.base};
    border: 1px solid ${theme.colors.primary.black.base} !important;
    color: ${theme.colors.primary.white.lightest};

    &:hover,
    &:active,
    &:focus {
      background: ${theme.colors.primary.black.base};
      border: 1px solid ${theme.colors.primary.black.base} !important;
    }

    img {
      max-height: 20px;
    }
  `}
  ${withFlexCSS};
`;

export const ThirdPartyPaymentButton: React.FC<Props> = ({
  showButton,
  intlId,
  filename,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const alt = formatMessage({ id: intlId });
  const src = `${process.env.PUBLIC_PATH}${filename}`;

  if (!showButton) {
    return null;
  }

  return (
    <StyledButton
      alignItems="center"
      justifyContent="center"
      fullWidth={true}
      {...props}
    >
      <img src={src} alt={alt} />
    </StyledButton>
  );
};

export const ApplePayButton: React.FC<SharedProps> = props => (
  <ThirdPartyPaymentButton
    data-qaid="apple-pay-button"
    filename="pay-with-apple-pay-button-white.svg"
    intlId="checkout.payment.applePayAlt"
    {...props}
  />
);

export const GooglePayButton: React.FC<SharedProps> = props => (
  <ThirdPartyPaymentButton
    data-qaid="google-pay-button"
    filename="buy-with-gpay-white.svg"
    intlId="checkout.payment.googlePayAlt"
    {...props}
  />
);
