import React, { RefObject, useEffect, useState } from 'react';
import { Field, Formik, FormikProps } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { useIntl } from 'react-intl';

import { OnSignUpSuccess, RenderCompetitionOptIn } from 'app/typings';
import { useAnalyticsContext } from 'app/shared/utils';
import {
  useAuthFormContext,
  useMultiStepSignUpContext,
} from 'app/shared/context';
import { Box, Typography } from 'app/shared/components/atoms';
import {
  ActionLink,
  AuthApiError,
  FormErrorMessage,
  InvisibleCaptcha,
  PasswordField,
  Textfield,
} from 'app/shared/components/molecules';
import {
  ThirdPartyAuth,
  ThirdPartyAuthMode,
} from 'app/shared/components/organisms';
import { useAccountSignUpForm } from 'app/fan/utils/useAccountSignUpForm';
import { ModalButtonsSection, ModalTitle } from 'app/fan/components/molecules';

import { ClaimAccountModal } from './ClaimAccountModal';
import { SignUpFormSchema } from './SignUpFormSchema';

interface CreateAccountProps {
  onSuccess: OnSignUpSuccess;
  onError?: () => void;
  formContext: string;
  renderCompetitionOptIn?: RenderCompetitionOptIn;
  signUpHeader?: string;
  continueToPaymentStageAsGuest?: () => void;
  onClickSignIn?: () => void;
  reCaptchaRef: RefObject<ReCAPTCHA>;
  testMode?: boolean;
  testResponse?: any;
  'data-qaid'?: string;
}

export const CreateAccountForm: React.FC<CreateAccountProps> = ({
  onSuccess,
  onError,
  formContext,
  renderCompetitionOptIn,
  signUpHeader,
  continueToPaymentStageAsGuest,
  onClickSignIn,
  reCaptchaRef,
  testMode,
  testResponse,
  'data-qaid': qaId,
}) => {
  const { formatMessage } = useIntl();
  useMultiStepSignUpContext(); // Raise error if not a descendent of MultiStepSignUpContextProvider
  const { isCompleteSignUpFlow, apiError } = useAuthFormContext();
  const [isClaimAccountModalOpen, setIsClaimAccountModalOpen] = useState(false);
  const [unclaimedAccountEmail, setUnclaimedAccountEmail] = useState('');
  const { trackAnalyticsEvent } = useAnalyticsContext();

  const {
    onSignUpFormSubmit,
    initialValues,
    isCaptchaEnv,
    displayCaptchaError,
    setDisplayCaptchaError,
  } = useAccountSignUpForm({
    onSuccess,
    onError,
    toggleClaimAccountModal: () => setIsClaimAccountModalOpen(true),
    setUnclaimedAccountEmail,
  });

  useEffect(() => {
    if (!isCompleteSignUpFlow) {
      trackAnalyticsEvent(
        'Account Signup Form Modal Displayed',
        {
          form_context: formContext,
        },
        true
      );
    }
  }, []);

  if (isCompleteSignUpFlow) {
    return null;
  }

  return (
    <Box data-qaid={qaId} flexDirection="column" gap={8}>
      {(signUpHeader || onClickSignIn) && (
        <Box flexDirection="column" gap={4}>
          {signUpHeader && <ModalTitle title={signUpHeader} />}
          {onClickSignIn && (
            <Typography>
              {formatMessage({
                id: 'authentication.signUp.alreadyHaveAnAccount',
              })}{' '}
              <ActionLink
                data-qaid="authentication-login-link"
                onClick={onClickSignIn}
                fontWeight="medium"
                variant="secondary"
              >
                {formatMessage({ id: 'authentication.signIn' })}
              </ActionLink>
            </Typography>
          )}
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={SignUpFormSchema({
          formatMessage,
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCaptchaEnv) {
            reCaptchaRef.current?.executeAsync().then(token => {
              setSubmitting(true);
              reCaptchaRef.current?.reset();
              onSignUpFormSubmit({ values, setSubmitting, token });
            });
          } else {
            setSubmitting(true);
            onSignUpFormSubmit({ values, setSubmitting });
          }
        }}
      >
        {(formikProps: FormikProps<any>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = formikProps;

          return (
            <form data-qaid="auth-signup-form" onSubmit={handleSubmit}>
              <InvisibleCaptcha
                reCaptchaRef={reCaptchaRef}
                setDisplayCaptchaError={setDisplayCaptchaError}
              />
              <Box flexDirection="column" gap={8}>
                <Field
                  autoComplete="one-time-code"
                  as={Textfield}
                  data-qaid={`${qaId}-email`}
                  label={formatMessage({ id: 'shared.emailAddress' })}
                  name="email"
                  type="email"
                  autoCapitalize="off"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.email && touched.email
                      ? String(errors.email)
                      : undefined
                  }
                  hasError={!!errors.email && !!touched.email}
                  touched={!!touched.email}
                  isRequired={true}
                />
                <Field
                  autoComplete="one-time-code"
                  as={PasswordField}
                  data-qaid={`${qaId}-password`}
                  label={formatMessage({ id: 'form.fields.password' })}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.password && touched.password
                      ? String(errors.password)
                      : undefined
                  }
                  hasError={!!errors.password && !!touched.password}
                  touched={!!touched.password}
                  isRequired={true}
                />
                {!!renderCompetitionOptIn &&
                  renderCompetitionOptIn({
                    formikProps,
                    authType: formatMessage({ id: 'shared.signUp' }),
                  })}
                {displayCaptchaError && (
                  <FormErrorMessage>
                    {formatMessage({
                      id: 'authentication.error.confirmYouAreNotARobot',
                    })}
                  </FormErrorMessage>
                )}
                {apiError && (
                  <AuthApiError
                    genericIntlId="authentication.error.genericSignupError"
                    onClickSignIn={onClickSignIn}
                  />
                )}
                <ModalButtonsSection
                  primaryCtaDataQaid="signup-form-submit"
                  primaryCtaText={formatMessage({
                    id: 'shared.signUp',
                  })}
                  isPrimaryCtaLoading={isSubmitting}
                  isPrimaryCtaDisabled={isCaptchaEnv && !reCaptchaRef.current}
                  primaryCtaType="submit"
                  isPrimaryCtaFullWidth={true}
                />
              </Box>
            </form>
          );
        }}
      </Formik>
      <ThirdPartyAuth
        onSuccess={onSuccess}
        onError={onError}
        mode={ThirdPartyAuthMode.SIGN_UP}
        testMode={testMode}
        testResponse={testResponse}
      />
      <ClaimAccountModal
        isOpen={isClaimAccountModalOpen}
        setIsOpen={setIsClaimAccountModalOpen}
        accountEmailAddress={unclaimedAccountEmail}
        continueToPaymentStageAsGuest={continueToPaymentStageAsGuest}
      />
    </Box>
  );
};

export default CreateAccountForm;
