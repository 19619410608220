import React from 'react';
import { useIntl } from 'react-intl';

import { Typography } from 'app/shared/components/atoms';
import { ActionLink } from 'app/shared/components/molecules/ActionLink';
import { Modal } from 'app/fan/components/molecules';

interface ClaimAccountModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountEmailAddress: string;
  continueToPaymentStageAsGuest?: () => void;
}

export const ClaimAccountModal: React.FC<ClaimAccountModalProps> = ({
  isOpen,
  setIsOpen,
  accountEmailAddress,
  continueToPaymentStageAsGuest,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      data-qaid="claim-account-modal"
      isFullscreenMobile={false}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={formatMessage({ id: 'authentication.claimAccount.title' })}
      disableClose={true}
      portalRootId="claim-account-modal-root"
    >
      <Typography>
        {formatMessage(
          { id: 'authentication.claimAccount.description' },
          { emailAddress: accountEmailAddress }
        )}
      </Typography>
      {continueToPaymentStageAsGuest && (
        <Typography>
          {formatMessage({
            id: 'authentication.claimAccount.guestCheckoutPre',
          })}
          <ActionLink onClick={continueToPaymentStageAsGuest}>
            {formatMessage({ id: 'authentication.claimAccount.guestCheckout' })}
          </ActionLink>
          {formatMessage({
            id: 'authentication.claimAccount.guestCheckoutPost',
          })}
        </Typography>
      )}
    </Modal>
  );
};

export default ClaimAccountModal;
