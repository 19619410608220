import React from 'react';

import { Spinner } from 'app/shared/components/atoms';
import { Box } from 'app/shared/components/atoms';
import { Modal } from 'app/fan/components/molecules/Modal';

interface StepWizardContentProps {
  stepOne: React.ReactNode;
  stepTwo: React.ReactNode;
  stepThree?: React.ReactNode;
  activeStep: number;
  isLoading?: boolean;
}

interface StepWizardProps extends StepWizardContentProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  disableClose?: boolean;
  'data-qaid'?: string;
  maxWidth?: string;
  renderModal?: boolean;
}

const StepWizardContent: React.FC<StepWizardContentProps> = ({
  stepOne,
  stepTwo,
  stepThree,
  activeStep,
  isLoading,
}) => {
  return (
    <>
      {isLoading && (
        <Box justifyContent="center" alignItems="center" p={4}>
          <Spinner />
        </Box>
      )}
      {!isLoading && activeStep === 1 && stepOne}
      {!isLoading && activeStep === 2 && stepTwo}
      {!isLoading && activeStep === 3 && stepThree}
    </>
  );
};

export const StepWizard: React.FC<StepWizardProps> = ({
  isOpen,
  setIsOpen,
  onClose,
  disableClose = false,
  maxWidth,
  renderModal,
  'data-qaid': qaId = 'wizard',
  ...props
}) => {
  if (!renderModal) {
    return (
      <Box data-qaid={qaId}>
        <StepWizardContent {...props} />
      </Box>
    );
  }

  return (
    <Modal
      maxWidth={maxWidth}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      data-qaid={qaId}
      onClose={onClose}
      isFullscreenMobile={true}
      disableClose={disableClose}
      onClickSecondaryCta={onClose}
    >
      <StepWizardContent {...props} />
    </Modal>
  );
};
