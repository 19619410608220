import React from 'react';
import { useIntl } from 'react-intl';

import { useCurrentTheme } from 'app/shared/theme';
import {
  SOFAR_MOBILE_TERMS_URL,
  SOFAR_PRIVACY_POLICY_URL,
} from 'app/shared/utils/siteUrls';
import { Typography } from 'app/shared/components/atoms';
import { Link } from 'app/fan/components/atoms';

interface Props {
  hasBackgroundColor: boolean;
  color?: string;
  fontSize?: string;
}

export const SmsMarketingDisclaimer: React.FC<Props> = ({
  hasBackgroundColor,
  color,
  fontSize = 'fontSize50',
}) => {
  const { colors } = useCurrentTheme();
  const { formatMessage } = useIntl();

  const smsMarketingDisclaimerColor = color
    ? color
    : hasBackgroundColor
    ? colors.primary.black.base
    : colors.primary.black.light;

  return (
    <Typography
      fontSize={fontSize}
      color={smsMarketingDisclaimerColor}
      data-qaid="sms-marketing-disclaimer"
    >
      {formatMessage({ id: 'smsSignUp.disclaimerPart1' })}
      <Link
        textDecoration="underline !important"
        href={SOFAR_PRIVACY_POLICY_URL}
        fontSize={fontSize}
        variant="secondary"
      >
        {formatMessage({ id: 'smsSignUp.disclaimerPrivacyPolicy' })}
      </Link>
      {' & '}
      <Link
        textDecoration="underline !important"
        href={SOFAR_MOBILE_TERMS_URL}
        fontSize={fontSize}
        variant="secondary"
      >
        {formatMessage({ id: 'smsSignUp.disclaimerTerms' })}
      </Link>
      .
    </Typography>
  );
};
