import { useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';
import RedditPixel from 'react-reddit-pixel';

import { isReviewApp } from 'app/lib/environmentChecker';
import google from 'app/lib/google';
import { City, OnSignUpSuccess } from 'app/typings';
import { useAnalyticsContext } from 'app/shared/utils';
import { useIsPageCitySpecific } from 'app/shared/utils/useIsPageCitySpecific';
import { CREATE_ACCOUNT } from 'app/shared/graphql/sessions/mutations';
import { useAuthFormContext } from 'app/shared/context';
import { useCityContext } from 'app/shared/context/City';
import { urlQueryParam } from 'app/fan/utils/urlQueryParam';

export interface SignUpFormValues {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
  competitionDataSharingOptIn: boolean;
  city?: City;
  mobile?: string;
  countryCode?: string;
}

type ReCaptchaToken = string | null;

export const useAccountSignUpForm = ({
  onSuccess,
  onError,
  city,
  setUnclaimedAccountEmail,
  toggleClaimAccountModal,
  userEmail,
  userFirstName,
  userLastName,
}: {
  onSuccess: OnSignUpSuccess;
  onError?: () => void;
  city?: City;
  setUnclaimedAccountEmail?: (email: string) => void;
  toggleClaimAccountModal?: () => void;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
}) => {
  const { lastViewedCity } = useCityContext();
  const { trackAnalyticsEvent } = useAnalyticsContext();
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const {
    setApiError,
    isCheckoutAuth,
    isPlusOneFlow,
    isCompleteSignUpFlow,
  } = useAuthFormContext();

  const isCaptchaEnv = !isReviewApp() && !!process.env.APP_ENV;
  const [reCaptchaToken, setReCaptchaToken] = useState<ReCaptchaToken>(null);
  const [displayCaptchaError, setDisplayCaptchaError] = useState(false);

  const { isPageCitySpecific } = useIsPageCitySpecific();

  const currentPageCitySlug = isPageCitySpecific
    ? lastViewedCity?.cachedSlug
    : undefined;

  const initialValues: SignUpFormValues = {
    email: userEmail || '',
    password: '',
    competitionDataSharingOptIn: false,
    city: city || lastViewedCity,
    firstName: userFirstName,
    lastName: userLastName,
    mobile: '',
    countryCode: city?.country.countryCode || '',
  };

  const onSignUpFormSubmit = async ({
    values: {
      firstName,
      lastName,
      email,
      password,
      competitionDataSharingOptIn,
      city,
      mobile,
      countryCode,
    },
    setSubmitting,
    token,
  }: {
    values: SignUpFormValues;
    setSubmitting: (isSubmitting: boolean) => void;
    token?: string | null;
  }) => {
    const isFromArtistApplication =
      urlQueryParam('return_to') === '/about/artists/apply';

    const numberBody = mobile && mobile.length > 0 ? mobile : undefined;

    if (isCaptchaEnv && !isCompleteSignUpFlow && !token) {
      trackAnalyticsEvent('ReCAPTCHA token missing', {
        is_checkout_auth: isCheckoutAuth,
        is_plus_one_flow: isPlusOneFlow,
        is_complete_sign_up_flow: isCompleteSignUpFlow,
        is_from_artist_application: isFromArtistApplication,
      });
      setDisplayCaptchaError(true);
      setSubmitting(false);
      return;
    }

    try {
      const createAccountResponse = await createAccount({
        variables: {
          reCaptchaToken: token,
          firstName,
          lastName,
          email,
          mobile: {
            countryCode: numberBody ? countryCode?.toUpperCase() : undefined,
            numberBody,
          },
          optInFanMarketingSmses: !!numberBody,
          optOutFanMarketingEmails: isFromArtistApplication,
          password,
          citySlug:
            (city && city.cachedSlug) ||
            city?.cachedSlug ||
            currentPageCitySlug,
        },
      });

      if (createAccountResponse?.data?.createAccount?.user?.isGuest) {
        setUnclaimedAccountEmail && setUnclaimedAccountEmail(email);
        toggleClaimAccountModal && toggleClaimAccountModal();
        setSubmitting(false);
        return;
      }

      if (createAccountResponse?.data?.createAccount.sessionId) {
        setSubmitting(false);
        google.dataLayer({ event: 'account_signup', email });
        const user = createAccountResponse.data.createAccount.user;
        if (process.env.APP_ENV) {
          RedditPixel.init('a2_ekhq3a2jqrod');
          RedditPixel.track('SignUp');
        }
        onSuccess({
          competitionDataSharingOptIn,
          userHomeCity: user?.homeCity?.cachedSlug,
          user,
          isNewAccount: createAccountResponse?.data?.createAccount.isNewAccount,
        });
      }
    } catch (e) {
      setSubmitting(false);
      console.log({ e });
      if (e instanceof Error) {
        setApiError(e);
        onError && onError();
      }
    }
  };

  return useMemo(() => {
    return {
      onSignUpFormSubmit,
      initialValues,
      isCaptchaEnv,
      reCaptchaToken,
      setReCaptchaToken,
      displayCaptchaError,
      setDisplayCaptchaError,
    };
  }, [
    onError,
    onSuccess,
    toggleClaimAccountModal,
    setUnclaimedAccountEmail,
    city,
    isCaptchaEnv,
    reCaptchaToken,
    displayCaptchaError,
  ]);
};
